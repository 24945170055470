import { Link, Outlet, useLocation } from "react-router-dom";
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useTheme } from "@mui/material";
import { AccountCircle, BugReport, CalendarToday, HomeWork, InsertChartOutlined } from "@mui/icons-material";
import { NavDrawerHeader } from "./NavDrawerHeader";
import { NavDrawerFooter } from "./NavDrawerFooter";
import colors from "../constants/colors";
import { createContext, useContext, useEffect, useState } from "react";
import { IS_DEV } from "../App";
import { IsMobileContext } from "../MobileValidator";

export const COLLAPSED_WIDTH = 104;
export const EXPANDED_WIDTH = 320;

export const SidebarWidthContext = createContext<number>(window.innerWidth < 1442 ? COLLAPSED_WIDTH : EXPANDED_WIDTH);

export function Page() {
    const theme = useTheme();
    let currentPath: string = useLocation().pathname.split("/")[1];
    const [navDrawerCollapsed, setNavDrawerCollapsed] = useState(window.innerWidth < 1442);
    const [navDrawerWidth, setNavDrawerWidth] = useState(window.innerWidth < 1442 ? COLLAPSED_WIDTH : EXPANDED_WIDTH);
    const isMobile = useContext(IsMobileContext);

    useEffect(() => {
        const query = window.matchMedia("(max-width: 1442px)");

        function handleQueryChange(queryEvent: MediaQueryListEvent) {
            /* The matches property will be true if the window width is below the mobile size. */
            setNavDrawerCollapsed(queryEvent.matches);
            setNavDrawerWidth(queryEvent.matches ? COLLAPSED_WIDTH : EXPANDED_WIDTH);
        }

        query.addEventListener('change', handleQueryChange);

        return () => {
            query.removeEventListener('change', handleQueryChange);
        };
    }, []);

    return (
        <Box display="flex" alignItems="flex-start" flexDirection="row" >
            {!isMobile &&
                <Drawer sx={{ width: navDrawerWidth }} variant="permanent" anchor="left" PaperProps={{ sx: { backgroundColor: colors.SchemesSurfaceContainerLow, borderRight: "0" } }} >
                    <Box borderRadius={theme.spacing(4)} margin={theme.spacing(2)} sx={{ width: `calc(${navDrawerWidth}px - ${theme.spacing(2)})`, padding: theme.spacing(2), paddingTop: theme.spacing(4), height: "100%", display: "flex", flexDirection: "column", backgroundColor: "white" }} >
                        <NavDrawerHeader navDrawerCollapsed={navDrawerCollapsed} />
                        <Box flex="1">
                            <List>
                                <ListItem color="inherit" component={Link} to={"/"} >
                                    <ListItemButton selected={currentPath == ""} color="inherit">
                                        <ListItemIcon>
                                            <InsertChartOutlined />
                                        </ListItemIcon>
                                        {!navDrawerCollapsed &&
                                            <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "" ? "bold" : "regular", fontSize: 18 }} color="inherit" primary="Dashboard" />
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <ListItem color="inherit" component={Link} to={"/Properties/"}>
                                    <ListItemButton selected={currentPath == "/Properties/"}>
                                        <ListItemIcon>
                                            <HomeWork sx={{ margin: 0 }} />
                                        </ListItemIcon>
                                        {!navDrawerCollapsed &&
                                            <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "Properties" ? "bold" : "regular", fontSize: 18 }} color="inherit" primary="My Properties" />
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <ListItem component={Link} to={"/Calendar/"}>
                                    <ListItemButton selected={currentPath == "/Calendar/"}>
                                        <ListItemIcon>
                                            <CalendarToday />
                                        </ListItemIcon>
                                        {!navDrawerCollapsed &&
                                            <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "Calendar" ? "bold" : "regular", fontSize: 18 }} primary="Calendar" />
                                        }
                                    </ListItemButton>
                                </ListItem>
                                {
                                    IS_DEV &&
                                    <ListItem component={Link} to={"/Test/"}>
                                        <ListItemButton selected={currentPath == "/Test/"}>
                                            <ListItemIcon>
                                                <BugReport />
                                            </ListItemIcon>
                                            {!navDrawerCollapsed &&
                                                <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "Test" ? "bold" : "regular", fontSize: 18 }} primary="Test" />
                                            }
                                        </ListItemButton>
                                    </ListItem>
                                }
                            </List>
                        </Box>
                        <NavDrawerFooter navDrawerCollapsed={navDrawerCollapsed} setNavDrawerCollapsed={setNavDrawerCollapsed} setNavDrawerWidth={setNavDrawerWidth} />
                    </Box>
                </Drawer>
            }
            <Box width={isMobile ? "100vw" : `calc(100vw - ${navDrawerWidth}px)`} height={isMobile ? "calc(100vh - 48px)" : "100vh"} sx={{ background: colors.SchemesSurfaceContainerLow, overflowX: "clip"}} overflow={"auto"} >
                <SidebarWidthContext.Provider value={navDrawerWidth}>
                    <Outlet />
                </SidebarWidthContext.Provider>
            </Box>
            {
                isMobile &&
                <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
                    <Toolbar sx={{height: "48px"}}>
                        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-around" gap={theme.spacing(1)} >
                            <IconButton href={"/"}>
                                <InsertChartOutlined color={currentPath == "" ? "primary" : "action"} />
                            </IconButton>
                            <IconButton href={"/Properties/"}>
                                <HomeWork color={currentPath == "Properties" ? "primary" : "action"} />
                            </IconButton>
                            <IconButton href={"/Calendar/"}>
                                <CalendarToday color={currentPath == "Calendar" ? "primary" : "action"} />
                            </IconButton>
                            <IconButton href={"/Account/"}>
                                <AccountCircle color={currentPath == "Account" ? "primary" : "action"} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            }
        </Box>
    );
}