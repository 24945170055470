import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ORIGIN, STRIPE_PROMISE } from "../App";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useMsal } from "@azure/msal-react";
import { Loading } from "./Loading";
import { Navigate } from "react-router-dom";
import { STRIPE_API_PATH } from "../constants/apiPaths";
import { READ_SCOPE, WRITE_SCOPE } from "../constants/scopes";
import { Logout } from "@mui/icons-material";

export function StripeCheckout() {
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [paid, setPaid] = useState<boolean | null>(null);

    async function fetchSecretDumb() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const priceId = new URLSearchParams(window.location.search).get("priceId");
        const queryString = priceId === null || priceId === "" ? "" : `?priceId=${priceId}`;
        const response = await fetch(ORIGIN + STRIPE_API_PATH + "create-stripe-checkout-session" + queryString, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + accessToken.accessToken
            }
        });

        const content = await response.json();
        const secret = content.clientSecret;

        return secret;
    }

    // const fetchClientSecret = useCallback(() => {
    //         // Create a Checkout Session
    //         return fetch(ORIGIN + "/create-stripe-checkout-session", {
    //         method: "POST",
    //         })
    //         .then((res) => res.json())
    //         .then((data) => data.clientSecret);
    //     }, []
    // );

    const fetchClientSecret = useCallback(fetchSecretDumb, [user, instance]);

    const options = { fetchClientSecret };

    async function FetchPaid() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        // @ts-ignore
        setPaid(accessToken.idTokenClaims.subscriptionStatus === "paid" || accessToken.idTokenClaims.subscriptionStatus === "perProperty");
    }

    useEffect(() => {
        if (paid === null) FetchPaid();
    }, [paid, instance, user]);

    return (paid === null ? <Loading /> : (paid ? <Navigate to="/" /> :
        <Box bgcolor="#F6F2FF" minHeight="100vh">
            <Box display="flex" flexDirection="row" padding={1} alignItems={"center"}>
                <Box flex={1} />
                {
                    user.username !== "" &&
                    <Typography margin={1} textAlign={"right"}>{"Signed in as: " + user.username}</Typography>
                }
                <Tooltip title="Logout">
                    <IconButton color="primary" onClick={() => instance.logoutRedirect()}>
                        <Logout />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box height={"100%"} display="flex" flexDirection="column" justifyContent="center">
                <EmbeddedCheckoutProvider
                    stripe={STRIPE_PROMISE}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </Box>
        </Box>
    ));
}