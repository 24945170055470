import { OccupantDTO, TenantDTO } from "./dataTransferObjects";

export interface ReductoResponse<T> {
    usage: {
        num_pages: number,
        num_fields: number
    },
    result: T[]
}

export const leaseSchema = {
    "type": "object",
    "properties": {
        "address": {
            "type": "object",
            "properties": {
                "premiseAddress": {
                    "type": "string",
                    "description": "The physical address of the leased property."
                },
                "unitNumber": {
                    "type": "string",
                    "description": "Unit number of apartment or suite, if applicable."
                },
                "city": {
                    "type": "string",
                    "description": "City in which the property is located."
                },
                "state": {
                    "type": "string",
                    "description": "State in which the property is located."
                },
                "zip": {
                    "type": "string",
                    "description": "Zip code for the property."
                },
                "county": {
                    "type": "string",
                    "description": "County in which the property is located."
                }
            },
            "required": [
                "premiseAddress",
                "city",
                "state",
                "zip"
            ]
        },
        "leaseInformation": {
            "type": "object",
            "properties": {
                "dateOfLease": {
                    "type": "string",
                    "description": "Date when the lease was executed in ISO 8601 format."
                },
                "leaseStart": {
                    "type": "string",
                    "description": "Start date of the lease term in ISO 8601 format."
                },
                "leaseEnd": {
                    "type": "string",
                    "description": "End date of the lease term in ISO 8601 format."
                },
                "dueDate": {
                    "type": "number",
                    "description": "Day of the month rent is due."
                },
                "monthlyRent": {
                    "type": "number",
                    "description": "The amount of rent paid monthly."
                },
                "securityDeposit": {
                    "type": "number",
                    "description": "Amount paid as a security deposit to cover potential damages."
                },
                "petDeposit": {
                    "type": "number",
                    "description": "Deposit required for keeping pets, if applicable."
                }
            },
            "required": [
                "dateOfLease",
                "leaseStart",
                "leaseEnd",
                "monthlyRent"
            ]
        },
        "landlordDetails": {
            "type": "object",
            "properties": {
                "firstName": {
                    "type": "string",
                    "description": "Landlord's first name."
                },
                "lastName": {
                    "type": "string",
                    "description": "Landlord's last name."
                },
                "address": {
                    "type": "string",
                    "description": "Landlord's address."
                },
                "cellNumber": {
                    "type": "string",
                    "description": "Landlord's cell phone number."
                },
                "emailAddress": {
                    "type": "string",
                    "description": "Landlord's email address."
                }
            },
            "required": [
                "firstName",
                "lastName",
                "address"
            ]
        },
        "tenantDetails": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "firstName": {
                        "type": "string",
                        "description": "Tenant's first name."
                    },
                    "lastName": {
                        "type": "string",
                        "description": "Tenant's last name."
                    },
                    "email": {
                        "type": "string",
                        "description": "Tenant's email address."
                    },
                    "phoneNumber": {
                        "type": "string",
                        "description": "Tenant's phone number."
                    }
                },
                "required": [
                    "firstName",
                    "lastName"
                ]
            },
            "description": "List of tenants."
        },
        "occupants": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "firstName": {
                        "type": "string",
                        "description": "First name of an occupant."
                    },
                    "lastName": {
                        "type": "string",
                        "description": "Last name of an occupant."
                    },
                    "phone": {
                        "type": "string",
                        "description": "Occupant's phone number."
                    },
                    "email": {
                        "type": "string",
                        "description": "Occupant's email address."
                    },
                }
            },
            "description": "List of occupants other than the tenants."
        },
        // "premiseFeatures": {
        // "type": "object",
        // "properties": {
        //     "smokingPermitted": {
        //     "type": "boolean",
        //     "description": "Indicates whether smoking is permitted in the property."
        //     },
        //     "parking": {
        //     "type": "object",
        //     "properties": {
        //         "included": {
        //         "type": "boolean",
        //         "description": "Indicates if parking is included with the lease."
        //         },
        //         "numberOfSpaces": {
        //         "type": "integer",
        //         "description": "Number of parking spaces provided."
        //         },
        //         "spaceNumber": {
        //         "type": "string",
        //         "description": "Specific parking space number(s) allocated."
        //         },
        //         "garageDoorOpeners": {
        //         "type": "integer",
        //         "description": "Number of garage door openers provided."
        //         },
        //         "type": {
        //         "type": "string",
        //         "description": "Type of parking provided (e.g., covered, uncovered, garage)."
        //         },
        //         "monthlyFee": {
        //         "type": "number",
        //         "description": "Monthly parking fee, if applicable."
        //         },
        //         "feePaidBy": {
        //         "type": "string",
        //         "enum": [
        //             "landlord",
        //             "tenant"
        //         ],
        //         "description": "Indicates who pays the parking fee."
        //         }
        //     }
        //     },
        //     "storage": {
        //     "type": "object",
        //     "properties": {
        //         "included": {
        //         "type": "boolean",
        //         "description": "Indicates if storage space is included in the lease."
        //         },
        //         "spaceNumber": {
        //         "type": "string",
        //         "description": "Storage space number."
        //         },
        //         "location": {
        //         "type": "string",
        //         "description": "Location of the storage space within the property."
        //         },
        //         "monthlyFee": {
        //         "type": "number",
        //         "description": "Monthly storage fee."
        //         },
        //         "feePaidBy": {
        //         "type": "string",
        //         "enum": [
        //             "landlord",
        //             "tenant"
        //         ],
        //         "description": "Indicates who is responsible for the storage fee."
        //         }
        //     }
        //     },
        //     "appliancesIncluded": {
        //     "type": "object",
        //     "properties": {
        //         "refrigerator": {
        //         "type": "boolean",
        //         "description": "Includes a refrigerator."
        //         },
        //         "oven": {
        //         "type": "boolean",
        //         "description": "Includes an oven."
        //         },
        //         "microwave": {
        //         "type": "boolean",
        //         "description": "Includes a microwave."
        //         },
        //         "dishwasher": {
        //         "type": "boolean",
        //         "description": "Includes a dishwasher."
        //         },
        //         "washer": {
        //         "type": "boolean",
        //         "description": "Includes a washing machine."
        //         },
        //         "dryer": {
        //         "type": "boolean",
        //         "description": "Includes a dryer."
        //         },
        //         "windowAirConditions": {
        //         "type": "boolean",
        //         "description": "Includes window air conditioning units."
        //         }
        //     }
        //     },
        //     "latePenaltyPercentage": {
        //     "type": "number",
        //     "description": "Percentage of the rent charged as a penalty for late payment."
        //     },
        //     "securityDepositReturnDate": {
        //     "type": "string",
        //     "description": "Date by which the security deposit should be returned after lease termination."
        //     },
        //     "tenantResponsibilities": {
        //     "type": "object",
        //     "properties": {
        //         "electricity": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for the electricity."
        //         },
        //         "gas": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for the gas."
        //         },
        //         "waterSewer": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for water and sewer services."
        //         },
        //         "heatingFuel": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for heating fuel."
        //         },
        //         "refuseRemoval": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for refuse removal."
        //         },
        //         "HOADues": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for Homeowner Association dues."
        //         },
        //         "snowIceRemoval": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for snow and ice removal."
        //         },
        //         "lawnMowing": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for lawn mowing."
        //         },
        //         "landscapeMaintenance": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for landscape maintenance."
        //         },
        //         "scavengerService": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for scavenger services."
        //         },
        //         "other": {
        //         "type": "string",
        //         "description": "Other responsibilities not explicitly mentioned."
        //         }
        //     }
        //     },
        //     "petPolicy": {
        //     "type": "object",
        //     "properties": {
        //         "allowed": {
        //         "type": "boolean",
        //         "description": "Indicates if the tenant is allowed to have pets."
        //         },
        //         "details": {
        //         "type": "array",
        //         "items": {
        //             "type": "object",
        //             "properties": {
        //             "type": {
        //                 "type": "string",
        //                 "description": "Type of pet allowed."
        //             },
        //             "weight": {
        //                 "type": "number",
        //                 "description": "Maximum weight allowed for the pet."
        //             },
        //             "number": {
        //                 "type": "integer",
        //                 "description": "Number of pets allowed."
        //             }
        //             }
        //         }
        //         }
        //     }
        //     }
        // }
        // }
    }
};

export interface LeaseSchemaResult {
    address: {
        premiseAddress: string,
        unitNumber: string,
        city: string,
        state: string,
        zip: string,
        county: string
    },
    leaseInformation: {
        dateOfLease: string,
        leaseStart: string,
        leaseEnd: string,
        dueDate?: number,
        monthlyRent: number,
        securityDeposit: number
    },
    landlordDetails: {
        firstName: string,
        lastName: string,
        address: string,
        cellNumber: string,
        emailAddress: string
    },
    tenantDetails?: TenantDTO[],
    occupants?: OccupantDTO[]
}

export const expenseSchema = {
    "type": "object",
    "properties": {
        "dateOfPurchase": {
            "type": "string",
            "description": "The date when the purchase or service was made, in ISO 8601 format."
        },
        "totalCost": {
            "type": "number",
            "description": "The total amount paid, including any applicable taxes and fees."
        },
        "detailedDescription": {
            "type": "string",
            "description": "A brief description of the purchased items or services provided."
        },
        "payerFirstName": {
            "type": "string",
            "description": "The first name of the person who made the payment."
        },
        "payerLastName": {
            "type": "string",
            "description": "The last name of the person who made the payment."
        },
        "payerEmail": {
            "type": "string",
            "description": "The email address of the person who made the payment."
        },
        "payerPhone": {
            "type": "string",
            "description": "The phone number of the person who made the payment."
        },
        "paymentMethod": {
            "type": "string",
            "description": "The method used for the payment, such as credit card, cash, or check."
        },
        "vendorName": {
            "type": "string",
            "description": "The name of the business or individual from whom the service/product was purchased."
        },
        "vendorEmail": {
            "type": "string",
            "description": "The email address of the vendor."
        },
        "vendorPhone": {
            "type": "string",
            "description": "The phone number of the vendor."
        },
        "vendorWebsite": {
            "type": "string",
            "description": "The website URL of the vendor."
        },
        "contractorFirstName": {
            "type": "string",
            "description": "The first name of the contractor who provided the service, if applicable."
        },
        "contractorLastName": {
            "type": "string",
            "description": "The last name of the contractor who provided the service."
        },
        "vendorAddress": {
            "type": "string",
            "description": "The full address of the vendor's business location, including street number, name, city, state, and zip code.",
            "items": {
                "type": "string"
            }
        },
        "summary": {
            "type": "string",
            "description": "A brief summary (fewer than 5 words) of the purchase.",
            "items": {
                "type": "string"
            }
        },
        "servicedAddress": {
            "type": "string",
            "description": "The full address of the location the purchase or service took place, including street number, name, city, state, and zip code.",
            "items": {
                "type": "string"
            }
        }
    },
    "required": [
        "dateOfPurchase",
        "totalCost",
        "summary",
        "detailedDescription"
    ]
};

export interface ExpenseSchemaResult {
    dateOfPurchase: string,
    totalCost: number,
    detailedDescription: string,
    payerFirstName: string,
    payerLastName: string,
    payerEmail: string,
    payerPhone: string,
    paymentMethod: string,
    vendorName: string,
    vendorEmail: string,
    vendorPhone: string,
    vendorWebsite: string,
    contractorFirstName: string,
    contractorLastName: string,
    vendorAddress: string,
    summary: string,
    servicedAddress: string,
}