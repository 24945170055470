import { Box, TextField, Button, Card, useTheme, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, DateTimePicker, LocalizationProvider, MobileDatePicker, MobileTimePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import colors from "../../../constants/colors";
import { Close } from "@mui/icons-material";
import { mobileModalStyle } from "../../../constants/styles";
import { CalendarEventDTO } from "../../../constants/dataTransferObjects";
import { BigEvent, CalendarEventPropertyInfo } from "../../calendar/calendarTypes";

interface EventFormFields {
    startTime: DateTime,
    endTime: DateTime,
    date: DateTime,
    allDay: boolean,
    title: string,
    summary: string,
    propertyInfo: CalendarEventPropertyInfo
}

interface EventFormProps {
    submitCreate: (e: CalendarEventDTO) => void,
    submitEdit: (e: CalendarEventDTO) => void,
    handleCloseModal: () => void,
    initialDate: DateTime,
    propertyInfos: CalendarEventPropertyInfo[] | null
    sourceEvent?: BigEvent
}

const nonePropertyInfo: CalendarEventPropertyInfo = {
    propertyAddress: "None",
    propertyId: 0,
};

export function MobileEventForm({ submitCreate, submitEdit, handleCloseModal, initialDate, propertyInfos, sourceEvent }: EventFormProps) {
    const theme = useTheme();
    let initialPropertyInfo = nonePropertyInfo;
    if (sourceEvent !== undefined && propertyInfos !== null) {
        initialPropertyInfo = propertyInfos.find(p => p.propertyId == sourceEvent.resource.calendarEventPropertyInfo.propertyId) ?? nonePropertyInfo;
    }

    const [fields, setFields] = useState<EventFormFields>({
        title: sourceEvent !== undefined ? sourceEvent.title : "",
        startTime: sourceEvent !== undefined ? DateTime.fromJSDate(sourceEvent.start) : initialDate,
        endTime: sourceEvent !== undefined ? DateTime.fromJSDate(sourceEvent.end) : initialDate,
        date: sourceEvent !== undefined ? DateTime.fromJSDate(sourceEvent.start) : initialDate,
        allDay: sourceEvent !== undefined ? sourceEvent.allDay : false,
        summary: sourceEvent !== undefined ? sourceEvent.resource.summary : "",
        propertyInfo: initialPropertyInfo
    });

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    function handleDateChange(newDate: DateTime | null) {
        if (newDate === null) return;
        setFields(prevData => ({
            ...prevData,
            date: newDate
        }))
    }

    function handleStartTimeChange(newDate: DateTime | null) {
        if (newDate === null) return;
        setFields(prevData => ({
            ...prevData,
            startTime: newDate
        }))
    }

    function handleEndTimeChange(newDate: DateTime | null) {
        if (newDate === null) return;
        setFields(prevData => ({
            ...prevData,
            endTime: newDate
        }))
    }

    function handlePropertySelectionChange(event: SelectChangeEvent) {
        if (event.target.value === null) return;
        console.log(event.target.value);
        // @ts-ignore
        setFields(prevData => ({
            ...prevData,
            propertyInfo: event.target.value
        }))
    }

    async function onAllDayCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFields(prevData => ({
            ...prevData,
            allDay: event.target.checked
        }));
    }

    async function handleSubmitCreate(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const start: DateTime = fields.date.set({
            hour: fields.startTime.hour,
            minute: fields.startTime.minute,
            second: fields.startTime.second,
            millisecond: fields.startTime.millisecond
        });
        let end = fields.date.set({
            hour: fields.endTime.hour,
            minute: fields.endTime.minute,
            second: fields.endTime.second,
            millisecond: fields.endTime.millisecond
        });
        if (end < start) end = end.plus({ day: 1 });
        let newEvent: CalendarEventDTO = {
            title: fields.title,
            summary: fields.summary,
            start: start.toISO() as string,
            end: end.toISO() as string,
            allDay: fields.allDay,
            id: -1,
            propertyAddress: fields.propertyInfo.propertyAddress,
            propertyId: fields.propertyInfo.propertyId,
        };
        if (sourceEvent !== undefined) {
            newEvent.id = sourceEvent.resource.id;
            submitEdit(newEvent);
        }
        else {
            submitCreate(newEvent);
        }
    }

    return (
        <Card component="form" onSubmit={handleSubmitCreate} sx={mobileModalStyle}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography fontSize="28px" fontWeight={500} lineHeight="30px" color={colors.greyVioletDark} >{sourceEvent !== undefined ? "Edit event" : "Create event"}</Typography>
                <Box flex={1} />
                <IconButton onClick={handleCloseModal}>
                    <Close />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" margin={`${theme.spacing(2)} 0`} gap={theme.spacing(2)}>
                <TextField fullWidth label="Title" placeholder="Inspection" name="title" value={fields.title} onChange={handleChange} />
                <FormControl fullWidth>
                    <InputLabel variant="filled" id="property-select-label">Choose property</InputLabel>
                    <Select
                        // @ts-ignore
                        labelId="property-select-label"
                        // @ts-ignore
                        value={fields.propertyInfo}
                        onChange={handlePropertySelectionChange}
                        variant="filled"
                    >
                        {/* @ts-ignore */}
                        <MenuItem value={nonePropertyInfo}>{nonePropertyInfo.propertyAddress}</MenuItem>
                        {propertyInfos?.map(i => {
                            // @ts-ignore
                            return <MenuItem value={i}>{i.propertyAddress}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <MobileDatePicker label="Date" value={fields.date} onChange={(value) => handleDateChange(value)} slotProps={{ textField: { fullWidth: true } }} />
                        </Grid>
                        {
                            !fields.allDay &&
                            <>
                                <Grid item xs={6}>
                                    <MobileTimePicker label="Start" value={fields.startTime} onChange={(value) => handleStartTimeChange(value)} slotProps={{ textField: { fullWidth: true } }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MobileTimePicker label="End" value={fields.endTime} onChange={(value) => handleEndTimeChange(value)} slotProps={{ textField: { fullWidth: true } }} />
                                </Grid>
                            </>
                        }
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel variant="filled" id="recurrence-select-label">Reccurence Behavior</InputLabel>
                                <Select
                                    // @ts-ignore
                                    value={fields.propertyInfo}
                                    labelId="recurrence-select-label"
                                    onChange={handlePropertySelectionChange}
                                    variant="filled"
                                >
                                    <MenuItem value={""}>Does not recur</MenuItem>
                                    <MenuItem value={"RRULE:FREQ=WEEKLY"}>Recurs Weekly</MenuItem>
                                    <MenuItem value={"RRULE:FREQ=WEEKLY;INTERVAL=2"}>Recurs Biweekly</MenuItem>
                                    <MenuItem value={"RRULE:FREQ=MONTHLY"}>Recurs Monthly</MenuItem>
                                    <MenuItem value={"RRULE:FREQ=YEARLY"}>Recurs Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox checked={fields.allDay} onChange={onAllDayCheckedChange} />} label="All Day Event?" />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <TextField fullWidth multiline minRows={3} label="Summary" placeholder="W 34th St., New York Inspector Arrival" name="summary" value={fields.summary} onChange={handleChange} />
            </Box>
            <Button type="submit" fullWidth variant="contained">
                {sourceEvent !== undefined ? "Confirm" : "Create event"}
            </Button>
        </Card>
    );
}