import React, { createContext, ReactNode, useEffect, useState } from "react";
import { isMobile } from "./mobileCheck";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import { Loading } from "./components/Loading";
import colors from "./constants/colors";
import illustration from "./images/illustration.svg";
import { useMsal } from "@azure/msal-react";
import { IS_DEV } from "./App";

interface Dimensions {
  innerWidth: number,
  innerHeight: number
}

interface Props {
  children: ReactNode
}

export const IsMobileContext = createContext<boolean>(isMobile());

export function MobileValidator({ children }: Props) {
  const [mobile, setMobile] = useState<boolean | null>(null);
  const theme = useTheme();
  const isLandscape = window.innerHeight < window.innerWidth;
  const { instance, accounts } = useMsal();
  const user = accounts[0];

  useEffect(() => {
    if (mobile === null) setMobile(isMobile());
  }, [mobile]);

  return (
    <IsMobileContext.Provider value={mobile ?? false}>
      {mobile === null ? <Loading /> : children}
    </IsMobileContext.Provider>
  );

  return (
    <IsMobileContext.Provider value={mobile ?? false}>
      {mobile === null ? <Loading /> : (mobile && !IS_DEV ?
        <Box
          width="100vw"
          height="100vh"
          sx={{ backgroundColor: colors.PropayaViolet }}
          padding={theme.spacing(1.5)}
        >
          <Card
            sx={{
              background: "white",
              borderRadius: theme.spacing(3),
              borderWidth: 0,
              height: "100%",
            }}
          >
            <Box
              height="calc(100vh - 72px)"
              margin={theme.spacing(3)}
              display="flex"
              flexDirection="column"
            >
              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ overflow: 'hidden' }}
              >
                <Box
                  component="img"
                  src={illustration}
                  sx={{
                    minHeight: "0px",
                    maxHeight: '100%',  // Constrain max height
                    maxWidth: '343px',
                    objectFit: 'contain'  // Ensure the image scales down while maintaining aspect ratio
                  }}
                />
              </Box>
              <Box
                flexShrink={0}
                marginTop={isLandscape ? theme.spacing(0) : theme.spacing(4)}
                marginBottom="5vh"
              >
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    textAlign: "center",
                    maxWidth: "400px",
                    margin: "auto"
                  }}
                >
                  Hi, {user.name?.split(" ")[0]}! Our mobile version is coming soon. For now, please use Propaya on your desktop. Thanks for your understanding.
                </Typography>
              </Box>
              <Button sx={{ height: "48px" }} variant="contained" onClick={() => instance.logoutRedirect()} >Sign Out</Button>
            </Box>
          </Card>
        </Box>
        :
        children
      )}
    </IsMobileContext.Provider>
  );
}