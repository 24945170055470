import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AccountCircle, ArrowBack, ArrowLeft, ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from "./Page";

interface NavDrawerFooterProps {
    navDrawerCollapsed: boolean
    setNavDrawerCollapsed: (collapsed: boolean) => void
    setNavDrawerWidth: (width: number) => void
}

export function NavDrawerFooter({ navDrawerCollapsed, setNavDrawerCollapsed, setNavDrawerWidth }: NavDrawerFooterProps) {
    const theme = useTheme();
    const { instance } = useMsal();
    let currentPath: string = useLocation().pathname.split("/")[1];

    async function OnClickLogout() {
        instance.logoutRedirect();
    }

    return (
        <Box marginTop="auto" marginBottom={0} display='inline-flex' flexDirection="column" width="100%" >
            <Box alignSelf="stretch">
                <Divider variant="middle" />
            </Box>
            <List>
                <ListItem >
                    <ListItemButton component={Link} to={"/Account/"}>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "Account" ? "bold" : "regular", fontSize: 18 }} primary="Account" />
                        }
                    </ListItemButton>
                </ListItem >
                <ListItem >
                    <ListItemButton onClick={() => {
                        setNavDrawerCollapsed(!navDrawerCollapsed)
                        if( navDrawerCollapsed ){
                            setNavDrawerWidth(EXPANDED_WIDTH);
                        }
                        else{
                            setNavDrawerWidth(COLLAPSED_WIDTH);
                        }
                    }}>
                        <ListItemIcon>
                            {
                                navDrawerCollapsed ? 
                                <ChevronRight />
                                :
                                <ChevronLeft />
                            }
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText primaryTypographyProps={{fontSize: 18}} primary="Collapse" />
                        }
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
}