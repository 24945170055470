import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ORIGIN } from "../App";
import { Box, Typography, Zoom } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import colors from "../constants/colors";
import { useMsal } from "@azure/msal-react";
import { CacheLookupPolicy } from "@azure/msal-browser"
import { USER_API_PATH } from "..";
import { STRIPE_API_PATH } from "../constants/apiPaths";
import { READ_SCOPE, WRITE_SCOPE } from "../constants/scopes";

export function StripeReturn() {
  const { instance, accounts } = useMsal();
  const user = accounts[0];
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [canRedirect, setCanRedirect] = useState(false);

  async function pollUntilPaid() {
    let accessToken;
    for (let i = 0; i < 40; i++) {
      accessToken = await instance.acquireTokenSilent({
        scopes: [READ_SCOPE],
        account: user,
        cacheLookupPolicy: CacheLookupPolicy.RefreshToken
      });
      // @ts-ignore
      if (accessToken.idTokenClaims.subscriptionStatus === "paid" || accessToken.idTokenClaims.subscriptionStatus === "perProperty") {
        setCanRedirect(true);
        accessToken = await instance.acquireTokenSilent({
          scopes: [READ_SCOPE],
          account: user,
        });
        return;
      }
      else {
        console.log("Still free...")
        console.log(accessToken.idTokenClaims);
      }
      await new Promise(resolve => setTimeout(resolve, 2000))
    }

    //error!!!!!!!!!
    setCanRedirect(true);
  }

  async function UpdateSubscriptionId() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const accessToken = await instance.acquireTokenSilent({
      scopes: [WRITE_SCOPE],
      account: user
    });

    const response = await fetch(ORIGIN + USER_API_PATH + "/SubscriptionId?session_id=" + sessionId, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + accessToken.accessToken
      },
      credentials: "include",
      mode: "cors",
    });

    const confirmation = await response.json();
    console.log(confirmation);

  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(ORIGIN + STRIPE_API_PATH + `stripe-session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  useEffect(() => {
    pollUntilPaid();
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    UpdateSubscriptionId();
    return (
      <>
        {canRedirect ? <Navigate to="/" /> :
          <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" bgcolor="#F6F2FF" gap="16px">
            <Zoom in={true} timeout={500} >
              <CheckCircle sx={{ width: 256, height: 256, color: colors.green }} />
            </Zoom>
            <Typography marginTop="64px" fontSize="18px" fontWeight={500} color={colors.greyDarker} lineHeight="normal">
              We appreciate your business! A subscription has been applied to {customerEmail}. If you have any questions, please email <a href="mailto:support@propaya.com">support@propaya.com</a>.
            </Typography>
            <Typography fontSize="16px" fontWeight={400} color={colors.greyDark} lineHeight="normal" >
              You will be redirected to Propaya shortly.
            </Typography>
          </Box>
        }
      </>
    )
  }

  return null;
}