import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { ORIGIN } from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType, EventMessage } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import { WRITE_SCOPE } from './constants/scopes';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

export const USER_API_PATH = "/api/ApplicationUser";

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

async function verifyUser(){
  const accessToken = await msalInstance.acquireTokenSilent({
    scopes: [WRITE_SCOPE],
    account: msalInstance.getAllAccounts()[0]!
  });
  const response = await fetch(ORIGIN + USER_API_PATH, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken.accessToken
    },
    mode: "cors",
    credentials: "include",
    // body: JSON.stringify(updatedLease), // body data type must match "Content-Type" header
  });
}

const callbackId = msalInstance.addEventCallback((message: EventMessage) => {
  // Update UI or interact with EventMessage here
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    verifyUser();
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
