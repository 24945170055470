import { Close } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Typography, useTheme } from "@mui/material";
import error from "../../../images/error.svg";
import colors from "../../../constants/colors";
import { BigEvent } from "../../calendar/calendarTypes";
import { mobileModalStyle } from "../../../constants/styles";

interface DeleteEventProps {
    event: BigEvent,
    handleCloseModal: () => void,
    handleDeleteCustomEvent: (id: number) => void,
}

export function MobileDeleteEvent({event, handleCloseModal, handleDeleteCustomEvent}: DeleteEventProps){
    const theme = useTheme();
    
    return (
        <Card sx={mobileModalStyle}>
            <Box display="flex" flexDirection="column" alignItems="center" >
                <Box width="100%" display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                    <Box flex={1} />
                    <IconButton onClick={handleCloseModal} sx={{backgroundColor: "white"}}>
                        <Close/>
                    </IconButton>
                </Box>
                <Box component="img" src={error} width="120px" height="120px" />
                <Typography
                    margin={`${theme.spacing(4)} 0`}
                    fontSize="28px"
                    fontWeight={500}
                    lineHeight="30px"
                    textAlign="center"
                >
                    {"Are you sure you want to delete \"" + event.title + "\"?"}
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={ () => handleDeleteCustomEvent(event.resource.id) }
                    color="secondary"
                    sx={{
                        color: colors.red
                    }}
                >
                    Yes, delete
                </Button>
                <Button fullWidth variant="contained" onClick={handleCloseModal} sx={{margin: theme.spacing(1)}} >No, keep it</Button>
            </Box>
        </Card>
    );
}