import { AppBar, Box, Button, ButtonBase, Card, Checkbox, Chip, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, Modal, TextField, Toolbar, Typography, styled, useTheme } from "@mui/material";
import { Add, ArrowBack, ArrowCircleRightOutlined, CheckBox, Close, CloudUploadOutlined, Delete, Download, Edit, EditNote, InsertDriveFileOutlined, UploadFile } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import { ORIGIN } from "../../App";
import { Property } from "./MobilePropertyList";
import { useMsal } from "@azure/msal-react";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { BlobClient, ContainerClient } from "@azure/storage-blob";
import { useDropzone } from "react-dropzone";
import { SidebarWidthContext } from "../Page";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import { EXPENSE_API_PATH, HOA_API_PATH, PROPERTY_API_PATH, UTILITY_API_PATH } from "../../constants/apiPaths";
import { READ_SCOPE, USER_IMPERSONATION_SCOPE, WRITE_SCOPE } from "../../constants/scopes";
import { ExpenseDTO, HOAPolicyDTO, LeaseDTO, OccupantDTO, OwnerDTO, PropertyDTO, TenantDTO, UtilityCompanyDTO, VendorDTO } from "../../constants/dataTransferObjects";
import { leaseSchema, LeaseSchemaResult, ReductoResponse } from "../../constants/reducto";
import { DownloadFile, GetAvailableBlobName, PreviewFile, USDollarFormatter, VisuallyHiddenInput } from "../../utils";
import { ExternalLinkButton } from "../ExternalLinkButton";
import { states } from "../../constants/states";
import colors from "../../constants/colors";
import error from "../../images/error.svg";
import normalizeUrl from "normalize-url";
import mime from 'mime';
var parser = require('parse-address');

export const INVALID_URL = "Invalid URL";

interface ExpenseRow {
    id: number,
    date: DateTime,
    cost: number,
    vendorName: string,
    vendorPhone: string,
    title: string,
    note: string,
    paymentMethod: string,
    paidByName: string,
    paidByEmail: string,
    paidByPhone: string,
    vendorWebsite: string
    contractorName: string,
}

const expenseColumnDefs: GridColDef[] = [
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        valueFormatter(params) {
            return params.value.toLocaleString();
        },
    },
    {
        field: 'cost',
        headerName: 'Cost',
        flex: 1,
        sortable: false,
        valueFormatter(params) {
            return USDollarFormatter.format(params.value);
        },
    },
    {
        field: 'vendorName',
        headerName: 'Vendor Name',
        flex: 1
    },
    {
        field: 'vendorPhone',
        headerName: 'Vendor Phone',
        flex: 1
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        sortable: false
    },
    {
        field: 'note',
        headerName: 'Note',
        flex: 1
    },
    {
        field: 'paymentMethod',
        headerName: 'Payment Method',
        flex: 1
    },
    {
        field: 'paidByName',
        headerName: 'Paid-By Name',
        flex: 1
    },
    {
        field: 'paidByEmail',
        headerName: 'Paid-By Email',
        flex: 1
    },
    {
        field: 'paidByPhone',
        headerName: 'Paid-By Phone',
        flex: 1
    },
    {
        field: 'vendorWebsite',
        headerName: 'Vendor Website',
        flex: 1
    },
    {
        field: 'contractorName',
        headerName: 'Contractor Name',
        flex: 1
    },
];

function ExpenseToRow(e: ExpenseDTO): ExpenseRow {
    return {
        id: e.id,
        date: DateTime.fromISO(e.date),
        cost: e.cost,
        vendorName: e.vendor.name,
        vendorPhone: e.vendor.phone,
        title: e.title,
        note: e.note,
        paymentMethod: e.paymentMethod,
        paidByEmail: e.paidByEmail,
        paidByName: e.paidByFirstName + " " + e.paidByLastName,
        paidByPhone: e.paidByPhone,
        vendorWebsite: e.vendor.website,
        contractorName: e.vendor.firstName + " " + e.vendor.lastName
    }
}

enum NoteTypes {
    electricity = "electricity",
    gas = "gas",
    water = "water",
    trash = "trash",
    HOA = "hoa",
}

const NOTE_TYPE_TITLES = {
    "electricity": "Electricity Note",
    "gas": "Gas Note",
    "water": "Water Note",
    "trash": "Trash Note",
    "hoa": "HOA Note",
}

export enum UploadState {
    NotUploaded,
    UploadingToBlob,
    ReductoProcessing,
    Done
}

interface PropertyFormFields {
    address: string
    owners: OwnerDTO[],
    expenses: ExpenseDTO[],
    city: string,
    state: string,
    zipCode: string,
    county: string,
    lease: LeaseDTO,
    hoaPolicy: HOAPolicyDTO,
    electricity: UtilityCompanyDTO,
    water: UtilityCompanyDTO,
    gas: UtilityCompanyDTO,
    trash: UtilityCompanyDTO,
}

interface LeaseTerm {
    start: DateTime | null,
    end: DateTime | null
}

interface MobilePropertyDetailProps {
    creationForm: boolean,
}

export function MobileUploadCardContent(file: File, uploadStatus: UploadState) {
    switch (uploadStatus) {
        case UploadState.NotUploaded:
            return (
                <>
                    <CloudUploadOutlined color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography textAlign="center">Take a photo, upload a file, or choose from your gallery.</Typography>
                </>
            );
        case UploadState.UploadingToBlob:
            return (
                <>
                    <UploadFile color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography>Uploading "{file.name}"...</Typography>
                    <Box width="60%">
                        <LinearProgress />
                    </Box>
                </>
            );
        case UploadState.ReductoProcessing:
            return (
                <>
                    <UploadFile color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography>Scanning "{file.name}"...</Typography>
                    <Box width="60%">
                        <LinearProgress />
                    </Box>
                </>
            );
        case UploadState.Done:
            return (
                <>
                    <CheckBox color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography>Done!</Typography>
                </>
            );
    }
}

export function MobilePropertyDetail({ creationForm }: MobilePropertyDetailProps) {
    const navigate = useNavigate();
    const navDrawerWidth = useContext(SidebarWidthContext);
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const { idInput } = useParams();
    const id = idInput == undefined ? undefined : parseInt(idInput);
    const theme = useTheme();
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxHeight: "90vh",
        bgcolor: 'white',
        border: '1px #DED8E1 solid',
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(4),
    }
    const [fields, setFields] = useState<PropertyFormFields>({
        address: "",
        owners: [{
            id: 0,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: ""
        }],
        expenses: [],
        city: "",
        state: "NY",
        zipCode: "",
        county: "",
        lease: {
            leaseStart: "",
            leaseEnd: "",
            pdfBlobName: "",
            rent: 0,
            securityDeposit: 0,
            tenants: [{
                id: 0,
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: ""
            }],
            occupants: [{
                id: 0,
                firstName: "",
                lastName: "",
                email: "",
                phone: ""
            }],
            dueDate: 1
        },
        hoaPolicy: {
            id: 0,
            associationName: "",
            monthlyFee: null,
            annualFee: null,
            note: "",
            autoPay: false,
            website: "",
            phone: ""
        },
        electricity: {
            id: 0,
            companyName: "",
            phone: "",
            website: "",
            autoPay: false,
            note: ""
        },
        water: {
            id: 0,
            companyName: "",
            phone: "",
            website: "",
            autoPay: false,
            note: ""
        },
        gas: {
            id: 0,
            companyName: "",
            phone: "",
            website: "",
            autoPay: false,
            note: ""
        },
        trash: {
            id: 0,
            companyName: "",
            phone: "",
            website: "",
            autoPay: false,
            note: ""
        },
    }); // set HOA Fees to 0 instead of null, update backend
    const [leaseTerm, setLeaseTerm] = useState<LeaseTerm>({ start: DateTime.now(), end: DateTime.now().plus({ year: 1 }) });
    const [property, setProperty] = useState<Property | null>(null);
    const [editing, setEditing] = useState(creationForm);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [uploadState, setUploadState] = useState(UploadState.NotUploaded);
    const [file, setFile] = useState(new File([], "No File Selected"));
    const [noteModalOpen, setNoteModalOpen] = useState(false);
    const [electricityNote, setElectricityNote] = useState("");
    const [gasNote, setGasNote] = useState("");
    const [waterNote, setWaterNote] = useState("");
    const [trashNote, setTrashNote] = useState("");
    const [hoaNote, setHOANote] = useState("");
    const [noteType, setNoteType] = useState(NoteTypes.electricity);
    const [electricWebsiteError, setElectricWebsiteError] = useState(false);
    const [waterWebsiteError, setWaterWebsiteError] = useState(false);
    const [gasWebsiteError, setGasWebsiteError] = useState(false);
    const [trashWebsiteError, setTrashWebsiteError] = useState(false);
    const [hoaWebsiteError, setHOAWebsiteError] = useState(false);
    const [uploadErrorText, setUploadErrorText] = useState("");
    const [expenseRows, setExpenseRows] = useState<ExpenseRow[]>([]);
    const [totalExpense, setTotalExpense] = useState(0);


    // TO DO: SWAP TO A FUNCTION because wtf is this
    const noteValues = {
        "gas": gasNote,
        "electricity": electricityNote,
        "water": waterNote,
        "trash": trashNote,
        "hoa": hoaNote,
    }

    const noteSetters = {
        "gas": setGasNote,
        "electricity": setElectricityNote,
        "water": setWaterNote,
        "trash": setTrashNote,
        "hoa": setHOANote,
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const parsed = parser.parseLocation(fields.address);
        let streetName: string = (parsed.street ?? "") + " " + (parsed.type ?? "");
        if (parsed.prefix !== "" && parsed.prefix !== undefined) streetName = parsed.prefix + " " + streetName;
        const data: PropertyDTO = {
            ...fields,
            id: id == undefined ? 0 : id,
            created: property?.created == undefined ? DateTime.now().toISO() : property.created.toISOString(),
            streetNumber: parsed.number,
            streetName: streetName,
        };
        let leaseStart = leaseTerm.start?.setZone("system");
        let leaseEnd = leaseTerm.end?.setZone("system");
        data.lease.leaseStart = leaseStart?.toISO() as string;
        data.lease.leaseEnd = leaseEnd?.toISO() as string;
        if (creationForm) {
            data.electricity.note = electricityNote;
            data.gas.note = gasNote;
            data.water.note = waterNote;
            data.trash.note = trashNote;
            data.hoaPolicy.note = hoaNote;
        }
        if (data.electricity.website != null && data.electricity.website != "") {
            try {
                data.electricity.website = normalizeUrl(data.electricity.website, { defaultProtocol: "https" });
            }
            catch {
                setElectricWebsiteError(true);
                return;
            }
        }
        setElectricWebsiteError(false);

        if (data.water.website != null && data.water.website != "") {
            try {
                data.water.website = normalizeUrl(data.water.website, { defaultProtocol: "https" });
            }
            catch {
                setWaterWebsiteError(true);
                return;
            }
        }
        setWaterWebsiteError(false);

        if (data.gas.website != null && data.gas.website != "") {
            try {
                data.gas.website = normalizeUrl(data.gas.website, { defaultProtocol: "https" });
            }
            catch {
                setGasWebsiteError(true);
                return;
            }
        }
        setGasWebsiteError(false);

        if (data.trash.website != null && data.trash.website != "") {
            try {
                data.trash.website = normalizeUrl(data.trash.website, { defaultProtocol: "https" });
            }
            catch {
                setTrashWebsiteError(true);
                return;
            }
        }
        setTrashWebsiteError(false);

        if (data.hoaPolicy.website != null && data.hoaPolicy.website != "") {
            try {
                data.hoaPolicy.website = normalizeUrl(data.hoaPolicy.website, { defaultProtocol: "https" });
            }
            catch {
                setHOAWebsiteError(true);
                return;
            }
        }
        setHOAWebsiteError(false);

        setFields({
            ...fields,
            electricity: {
                ...fields.electricity,
                website: data.electricity.website
            },
            water: {
                ...fields.water,
                website: data.water.website
            },
            gas: {
                ...fields.gas,
                website: data.gas.website
            },
            trash: {
                ...fields.trash,
                website: data.trash.website
            },
            hoaPolicy: {
                ...fields.hoaPolicy,
                website: data.hoaPolicy.website
            }
        });
        
        setEditing(false);
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const method = creationForm ? "POST" : "PUT";
        const suffix = creationForm ? "" : id?.toString();
        const response = await fetch(ORIGIN + PROPERTY_API_PATH + suffix, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        if (creationForm) navigate('/Properties/');
    };

    async function handleSaveNote() {
        if (creationForm) return setNoteModalOpen(false);
        let id;
        switch (noteType) {
            case NoteTypes.electricity:
                id = fields.electricity.id;
                break;
            case NoteTypes.water:
                id = fields.water.id;
                break;
            case NoteTypes.gas:
                id = fields.gas.id;
                break;
            case NoteTypes.trash:
                id = fields.trash.id;
                break;
            case NoteTypes.HOA:
                id = fields.hoaPolicy.id;
                break;
            default:
                id = 0;
                break;
        }
        if (id == 0) return; // failed
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });

        let note = noteValues[noteType];

        let fetchURL = ORIGIN + (noteType == NoteTypes.HOA ? HOA_API_PATH : UTILITY_API_PATH);
        const response = await fetch(fetchURL + id + "/Note?note=" + encodeURIComponent(note), {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
        });
        setNoteModalOpen(false);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    function handleOwnerChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        const newFields = {
            ...fields
        }
        newFields.owners[0] = {
            ...newFields.owners[0],
            [e.target.name]: e.target.value
        }
        setFields(newFields);
    }

    function handleHOAChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            hoaPolicy: {
                ...prevData.hoaPolicy,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleElectricChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        console.log(fields.electricity);

        setFields(prevData => ({
            ...prevData,
            electricity: {
                ...prevData.electricity,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleWaterChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            water: {
                ...prevData.water,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleGasChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            gas: {
                ...prevData.gas,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleTrashChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            trash: {
                ...prevData.trash,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleLeaseChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            lease: {
                ...prevData.lease,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleTenantChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        let id: number = parseInt(e.target.id[0])
        const newFields = {
            ...fields
        }
        newFields.lease.tenants[id] = {
            ...newFields.lease.tenants[id],
            [e.target.name]: e.target.value
        };
        setFields(newFields);
        console.log(fields);
    }

    function addTenant() {
        let newFields = {
            ...fields
        };
        newFields.lease.tenants.push({
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
        });
        setFields(newFields);
    }

    function removeTenant() {
        let newFields = {
            ...fields
        };
        newFields.lease.tenants.pop();
        setFields(newFields);
    }

    function handleOccupantChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        let id: number = parseInt(e.target.id[0])
        const newFields = {
            ...fields
        }
        newFields.lease.occupants[id] = {
            ...newFields.lease.occupants[id],
            [e.target.name]: e.target.value
        };
        setFields(newFields);
        console.log(fields);
    }

    function addOccupant() {
        let newFields = {
            ...fields
        };
        newFields.lease.occupants.push({
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });
        setFields(newFields);
    }

    function removeOccupant() {
        let newFields = {
            ...fields
        };
        newFields.lease.occupants.pop();
        setFields(newFields);
    }

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files == null) return setUploadErrorText("Invalid file, please upload correct file type.");
        if (event.target.files[0].size > 10_000_000) return setUploadErrorText("File too large.");
        FileChosen(event.target.files[0])
    }

    async function FileChosen(file: File) {
        setFile(file);
        setUploadState(UploadState.UploadingToBlob);
        const accessToken = await instance.acquireTokenSilent({
            scopes: [USER_IMPERSONATION_SCOPE],
            account: user
        });
        const response = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireStorageSAS", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const SAS = await response.json();
        const containerClient = new ContainerClient(SAS);
        const blockBlobClient = await GetAvailableBlobName("leases/" + file.name, containerClient);
        setFile(new File([file], blockBlobClient.name.substring(7), { type: file.type }));

        const uploadResponse = await blockBlobClient.uploadData(file);
        if (uploadResponse._response.status != 201) return;
        setUploadState(UploadState.ReductoProcessing);
        const readAccessToken = await instance.acquireTokenSilent({
            scopes: [USER_IMPERSONATION_SCOPE],
            account: user
        });
        const SASresponse = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireReadSAS?" + "blobName=" + encodeURIComponent(blockBlobClient.name), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + readAccessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const readSAS: string = await SASresponse.json();
        const requestBody = {
            document_url: readSAS,
            schema: leaseSchema
        }
        try {
            const reductoResponse = await fetch("https://v1.api.reducto.ai/extract", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    authorization: 'Bearer 4242ee92393c89ece12f0bd86b1e70c95bb770efcf5dbd0964f382909289350b357577c15260c897ec97c8ea567a328b'
                },
                body: JSON.stringify(requestBody)
            });
            const reductoResponseBody: ReductoResponse<LeaseSchemaResult> = await reductoResponse.json();
            const reductoResult = reductoResponseBody.result[0];
            console.log(reductoResponseBody);
            setFields({
                lease: {
                    leaseStart: reductoResult.leaseInformation.leaseStart,
                    leaseEnd: reductoResult.leaseInformation.leaseEnd,
                    pdfBlobName: blockBlobClient.name,
                    dueDate: reductoResult.leaseInformation.dueDate ?? 1,
                    rent: reductoResult.leaseInformation.monthlyRent,
                    securityDeposit: reductoResult.leaseInformation.securityDeposit,
                    tenants: reductoResult.tenantDetails ?? [],
                    occupants: reductoResult.occupants ?? []
                },
                owners: [{
                    id: 0,
                    phoneNumber: reductoResult.landlordDetails.cellNumber,
                    email: reductoResult.landlordDetails.emailAddress,
                    firstName: reductoResult.landlordDetails.firstName,
                    lastName: reductoResult.landlordDetails.lastName
                }],
                expenses: [],
                address: reductoResult.address.premiseAddress,
                city: reductoResult.address.city,
                state: reductoResult.address.state,
                zipCode: reductoResult.address.zip,
                county: reductoResult.address.county,
                hoaPolicy: {
                    id: 0,
                    associationName: "",
                    monthlyFee: 0,
                    annualFee: 0,
                    note: "",
                    autoPay: false,
                    website: "",
                    phone: "",
                },
                electricity: {
                    id: 0,
                    companyName: "",
                    phone: "",
                    website: "",
                    autoPay: false,
                    note: ""
                },
                water: {
                    id: 0,
                    companyName: "",
                    phone: "",
                    website: "",
                    autoPay: false,
                    note: ""
                },
                gas: {
                    id: 0,
                    companyName: "",
                    phone: "",
                    website: "",
                    autoPay: false,
                    note: ""
                },
                trash: {
                    id: 0,
                    companyName: "",
                    phone: "",
                    website: "",
                    autoPay: false,
                    note: ""
                },
            });
            setLeaseTerm({ start: DateTime.fromISO(reductoResult.leaseInformation.leaseStart), end: DateTime.fromISO(reductoResult.leaseInformation.leaseEnd) })
        }
        catch {
            alert("Failed to parse lease");
        }
        finally {
            setUploadState(UploadState.Done);
        }
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
        FileChosen(acceptedFiles[0])
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, accept: { 'application/pdf': [".pdf"], 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'], "image/heic": [] }, disabled: (uploadState != UploadState.NotUploaded) });

    async function FetchProperty() {
        if (id == undefined) return;
        const propertyAPIAccessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const propertyResponse = await fetch(ORIGIN + PROPERTY_API_PATH + id.toString(), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + propertyAPIAccessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        if (!propertyResponse.ok) return;
        let body: PropertyDTO = await propertyResponse.json();
        let fetchedProperty: Property = {
            ...body,
            created: new Date(body.created)
        };
        setElectricityNote(fetchedProperty.electricity.note);
        setWaterNote(fetchedProperty.water.note);
        setGasNote(fetchedProperty.gas.note);
        setTrashNote(fetchedProperty.trash.note);
        setHOANote(fetchedProperty.hoaPolicy.note);
        setProperty(fetchedProperty);
        setFields({
            address: fetchedProperty.streetNumber + " " + fetchedProperty.streetName,
            owners: fetchedProperty.owners.length == 0 ? [{ id: 0, firstName: "", lastName: "", phoneNumber: "", email: "" }] : [...fetchedProperty.owners],
            expenses: fetchedProperty.expenses,
            city: fetchedProperty.city,
            state: fetchedProperty.state,
            zipCode: fetchedProperty.zipCode,
            county: fetchedProperty.county,
            lease: fetchedProperty.lease,
            hoaPolicy: fetchedProperty.hoaPolicy,
            electricity: fetchedProperty.electricity,
            water: fetchedProperty.water,
            gas: fetchedProperty.gas,
            trash: fetchedProperty.trash,
        });
        setLeaseTerm({ start: DateTime.fromISO(fetchedProperty.lease.leaseStart), end: DateTime.fromISO(fetchedProperty.lease.leaseEnd) });
        setExpenseRows(fetchedProperty.expenses.map(ExpenseToRow));
        setTotalExpense(fetchedProperty.expenses.map( (e) => e.cost ).reduce((partialSum, cost) => partialSum + cost, 0));

        if (fetchedProperty.lease.pdfBlobName == "") return;
        const accessToken = await instance.acquireTokenSilent({
            scopes: ["api://propayafunctionsdev/user_impersonation"],
            account: user
        });
        const SASresponse = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireReadSAS?" + "blobName=" + encodeURIComponent(fetchedProperty.lease.pdfBlobName), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const SAS = await SASresponse.json();
        const blobClient = new BlobClient(SAS);
        const blob = await blobClient.download().then(response => response.blobBody);
        if (blob === undefined) return;
        const mimeFileType = mime.getType(blobClient.name) ?? "";
        const index = blobClient.name.indexOf("leases/");
        let blobName;
        if (index == -1) {
            blobName = blobClient.name;
        }
        else {
            blobName = blobClient.name.substring(index + 7);
        }
        setFile(new File([blob], blobName, { type: mimeFileType }));
        setUploadState(UploadState.Done);
    }

    async function handleDelete() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        await fetch(ORIGIN + PROPERTY_API_PATH + property?.id, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
        });
        navigate("..", { relative: "path" });
    }

    useEffect(() => {
        if (!creationForm && property === null) {
            FetchProperty();
        }
    }, [property]);

    useEffect(() => {
        // get id from URL
        const id = window.location.hash.slice(1);

        if (id == "expenses") {
            document.getElementById(id)?.scrollIntoView();
        }
    }, [])

    function handleNoteValueChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setter: Dispatch<SetStateAction<string>>) {
        event.preventDefault();
        setter(event.target.value);
    }

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigate("Expenses/" + params.id.toString())
    };

    return (
        <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit}>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Box margin={theme.spacing(3)} display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" width="100%">
                            <Box flex={1} />
                            <IconButton onClick={() => setDeleteModalOpen(false)}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box height="273px" display="flex" flexDirection="column" textAlign="center" justifyContent="center" alignItems="center">
                            <Box component="img" src={error} width="120px" height="120px" />
                            <Typography variant="h5" marginTop={theme.spacing(4)} >{"Are you sure you want to delete \"" + property?.streetNumber + " " + property?.streetName + "\"?"}</Typography>
                            <Box marginTop={theme.spacing(4)} gap={theme.spacing(1.5)} display="flex" flexDirection="row" justifyContent="center">
                                <Button variant="outlined" color="primary" onClick={() => setDeleteModalOpen(false)}>No, keep</Button>
                                <Button variant="contained" color="error" onClick={() => handleDelete()}>Yes, Delete</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={noteModalOpen}
                onClose={() => setNoteModalOpen(false)}
            >
                <Box sx={modalStyle} >
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" width="100%">
                            <Typography fontSize="28px" fontWeight={500} lineHeight="30px" color={colors.greyVioletDark} >{NOTE_TYPE_TITLES[noteType]}</Typography>
                            <Box flex={1} />
                            <IconButton onClick={() => setNoteModalOpen(false)}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box display="flex" flexDirection="column" margin={`${theme.spacing(4)} 0`} gap={theme.spacing(2)}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={8}
                                label="Summary"
                                placeholder="Notes go here..."
                                name="summary"
                                value={noteValues[noteType]}
                                onChange={(event) => handleNoteValueChange(event, noteSetters[noteType])}
                            />
                        </Box>
                    </Box>
                    <Button fullWidth variant="contained" onClick={handleSaveNote}>Save Note</Button>
                </Box>
            </Modal>
            <Card sx={{ margin: `${theme.spacing(2)}`, marginBottom: 0, bgcolor: "white", height: "84px", display: "flex", flexDirection: "row", alignItems: "center", padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}` }}>
                <Box flexGrow={1} display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="row" gap={theme.spacing(0.5)}>
                        <IconButton sx={{ background: "white" }} onClick={() => navigate('/Properties/')}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6">{creationForm ? "Add New Property" : (property == undefined ? "" : fields.address)}</Typography>
                    </Box>
                </Box>
                {editing ?
                    <Button sx={{ height: "48px", paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }} variant='contained' type="submit">Save</Button>
                    :
                    <Box gap={theme.spacing(1)} display="flex" flexDirection="row" >
                        <IconButton color="primary" onClick={() => setDeleteModalOpen(true)}>
                            <Delete />
                        </IconButton>
                        <IconButton color="primary" onClick={() => { setEditing(true) }}>
                            <Edit />
                        </IconButton>
                    </Box>
                }
            </Card>
            {(creationForm || file.size > 0) && <Box display="flex" flexDirection="column" sx={{
                margin: theme.spacing(2),
                gap: theme.spacing(2)
            }}>
                <Typography variant="h5">Lease PDF</Typography>
                <Card sx={{
                    padding: theme.spacing(3),
                }}>
                    {uploadState != UploadState.Done ?
                        <>
                            <Box
                                {...getRootProps()}
                                display="flex" flexDirection="column" alignItems="center" gap={theme.spacing(2)} sx={{
                                    background: colors.SchemesSurfaceContainerLow,
                                    padding: theme.spacing(4)
                                }}>
                                {MobileUploadCardContent(file, uploadState)}
                                <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                                {uploadState == UploadState.NotUploaded &&
                                    <>
                                        <Button component="label" variant="contained" color="secondary">Upload</Button>
                                        <Typography textAlign="center" variant="body2" fontSize={12} color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
                                        <Typography fontWeight={500} color={colors.red} >{uploadErrorText}</Typography>
                                    </>
                                }
                            </Box>
                        </>
                        :
                        <>
                            <Box display="flex" flexDirection="row" alignItems="center" >
                                <InsertDriveFileOutlined color="primary" sx={{ width: 32, height: 32 }} />
                                <Typography onClick={() => PreviewFile(file)} component={ButtonBase} color={colors.SchemesPrimary} sx={{ textDecoration: "underline" }} >{file.name}</Typography>
                                <Box flex="1" />
                                <IconButton color="primary" onClick={() => DownloadFile(file)}>
                                    <Download />
                                </IconButton>
                            </Box>
                        </>
                    }
                    { }
                </Card>
            </Box>}
            {
                !creationForm &&
                <Box display="flex" flexDirection="column" sx={{
                    margin: `${theme.spacing(2)}`,
                    gap: theme.spacing(2)
                }}>
                    <Box display="flex" flexDirection="row">
                        <Typography variant="h5">Expenses</Typography>
                        <Box flex={1} />
                        {
                            expenseRows.length !== 0 &&
                            <>
                                <Button startIcon={<Add />} variant='contained' onClick={() => navigate("Expenses/Add")}>Add Expense</Button>
                            </>
                        }
                    </Box>
                    <Card sx={{
                        padding: theme.spacing(3),
                        display: "flex",
                        flexDirection: "column",
                        minHeight: theme.spacing(15),
                        justifyContent: "center",
                    }}>
                        {expenseRows.length === 0 ?
                            <Button sx={{ width: "50%", margin: "auto", height: 48 }} startIcon={<Add />} variant='contained' onClick={() => navigate("Expenses/Add")}>Add Expense</Button>
                            :
                            <>
                                <DataGrid
                                    columns={expenseColumnDefs}
                                    rows={expenseRows}
                                    hideFooter={expenseRows.length < 25}
                                    sx={{
                                        "& .MuiDataGrid-row:hover": {
                                            cursor: "pointer"
                                        },
                                        '& .MuiDataGrid-cell:focus': {
                                            outline: 'none',
                                        },
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontSize: "16px",
                                            color: colors.greyVioletDark
                                        },
                                        '& .MuiDataGrid-columnHeader': {
                                            '& .MuiDataGrid-iconButtonContainer': {
                                                marginLeft: '8px'
                                            },
                                        },
                                        border: "0px"
                                    }}
                                    onRowClick={handleRowClick}
                                    disableRowSelectionOnClick
                                    disableColumnMenu
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                paymentMethod: false,
                                                paidByName: false,
                                                paidByEmail: false,
                                                paidByPhone: false,
                                                vendorWebsite: false,
                                                contractorName: false,
                                                vendorName: false,
                                                vendorPhone: false,
                                                note: false,
                                            },
                                            
                                        },
                                        sorting: {
                                            sortModel: [{ field: 'date', sort: 'asc' }],
                                        },
                                    }}
                                />
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginTop={theme.spacing(6)}>
                                    <Typography variant="h6" marginRight={theme.spacing(2)} >{"Total: " +  USDollarFormatter.format(totalExpense) }</Typography>
                                </Box>
                            </>
                        }
                    </Card>
                </Box>
            }
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2)
            }}>
                <Typography variant="h5">Property Info</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{
                            padding: theme.spacing(3),
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" >Address</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField disabled={!editing} fullWidth label="Address" placeholder="20 W 34th St." name="address" value={fields.address} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!editing} fullWidth label="City" placeholder="New York" name="city" value={fields.city} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!editing} fullWidth label="County" placeholder="New York County" name="county" value={fields.county} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        disabled={!editing}
                                        fullWidth
                                        value={fields.state}
                                        label="State"
                                        SelectProps={{
                                            renderValue: (value: any) => value
                                        }}
                                        name="state"
                                        onChange={handleChange}
                                    >
                                        {
                                            Object.keys(states).map((abbreviation) => <MenuItem key={abbreviation} value={abbreviation}>{states[abbreviation]}</MenuItem>)
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!editing} fullWidth label="Zip" placeholder="10001" name="zipCode" value={fields.zipCode} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{
                            padding: theme.spacing(3),
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" >Owner</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!editing} fullWidth label="First Name" placeholder="Warren" name="firstName" value={fields.owners[0].firstName} onChange={handleOwnerChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!editing} fullWidth label="Last Name" placeholder="Buffett" name="lastName" value={fields.owners[0].lastName} onChange={handleOwnerChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField disabled={!editing} fullWidth label="Email" placeholder="warren@buffett.com" name="email" value={fields.owners[0].email} onChange={handleOwnerChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField disabled={!editing} fullWidth label="Phone Number" placeholder="(123) 456-7890" name="phoneNumber" value={fields.owners[0].phoneNumber} onChange={handleOwnerChange} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2)
            }}>
                <Typography variant="h5">Lease</Typography>
                <Card sx={{
                    padding: theme.spacing(3),
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" >Lease</Typography>
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <Grid item xs={6}>
                                <MobileDatePicker disabled={!editing} slotProps={{ textField: { fullWidth: true } }} label="Lease Start" value={leaseTerm.start} onChange={(value) => setLeaseTerm({ start: value, end: leaseTerm.end })} />
                            </Grid>
                            <Grid item xs={6}>
                                <MobileDatePicker disabled={!editing} slotProps={{ textField: { fullWidth: true } }} label="Lease End" value={leaseTerm.end} onChange={(value) => setLeaseTerm({ start: leaseTerm.start, end: value })} />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={3}>
                            <TextField disabled={!editing} fullWidth label="Due Date" name="dueDate" value={fields.lease.dueDate} onChange={handleLeaseChange} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField disabled={!editing} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} fullWidth label="Rent" name="rent" value={fields.lease.rent} onChange={handleLeaseChange} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField disabled={!editing} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} fullWidth label="Security Deposit" name="securityDeposit" value={fields.lease.securityDeposit} onChange={handleLeaseChange} />
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2)
            }}>
                <Box display="flex" flexDirection="row">
                    <Typography variant="h5">Tenants</Typography>
                    <Box flex="1" />
                    {fields.lease.tenants.length < 3 && editing &&
                        <Button startIcon={<Add />} variant='contained' color="secondary" onClick={() => addTenant()}>Add Tenant</Button>
                    }
                </Box>
                {fields.lease.tenants.map((tenant, index) =>
                    <Card sx={{
                        padding: theme.spacing(3),
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} display="flex" flexDirection="row">
                                <Typography variant="h6">Tenant {" " + (index + 1)}</Typography>
                                <Box flex="1" />
                                {index == (fields.lease.tenants.length - 1) && editing && <IconButton onClick={() => removeTenant()}><Close /></IconButton>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField disabled={!editing} fullWidth label="First Name" id={index + "firstName"} name="firstName" value={tenant.firstName} onChange={handleTenantChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField disabled={!editing} fullWidth label="Last Name" id={index + "lastName"} name="lastName" value={tenant.lastName} onChange={handleTenantChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={!editing} fullWidth label="Email" id={index + "email"} name="email" value={tenant.email} onChange={handleTenantChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={!editing} fullWidth label="Phone Number" id={index + "phoneNumber"} name="phoneNumber" value={tenant.phoneNumber} onChange={handleTenantChange} />
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </Box>
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2)
            }}>
                <Box display="flex" flexDirection="row">
                    <Typography variant="h5">Occupants</Typography>
                    <Box flex="1" />
                    {fields.lease.occupants.length < 3 && editing &&
                        <Button startIcon={<Add />} variant='contained' color="secondary" onClick={() => addOccupant()}>Add Occupant</Button>
                    }
                </Box>
                {fields.lease.occupants.map((occupant, index) =>
                    <Card sx={{
                        padding: theme.spacing(3),
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} display="flex" flexDirection="row">
                                <Typography variant="h6">Occupant {" " + (index + 1)}</Typography>
                                <Box flex="1" />
                                {index == (fields.lease.occupants.length - 1) && editing && <IconButton onClick={() => removeOccupant()}><Close /></IconButton>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField disabled={!editing} fullWidth label="First Name" id={index + "firstName"} name="firstName" value={occupant.firstName} onChange={handleOccupantChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField disabled={!editing} fullWidth label="Last Name" id={index + "lastName"} name="lastName" value={occupant.lastName} onChange={handleOccupantChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={!editing} fullWidth label="Email" id={index + "email"} name="email" value={occupant.email} onChange={handleOccupantChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={!editing} fullWidth label="Phone Number" id={index + "phone"} name="phone" value={occupant.phone} onChange={handleOccupantChange} />
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </Box>
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2)
            }}>
                <Typography variant="h5">Utilities</Typography>
                <Card sx={{
                    padding: theme.spacing(3),
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Electricity</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Company" name="companyName" value={fields.electricity.companyName} onChange={handleElectricChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.electricity.phone} onChange={handleElectricChange} />
                        </Grid>
                        <Grid item xs={6}>
                            {editing ?
                                <TextField error={electricWebsiteError} helperText={electricWebsiteError ? INVALID_URL : ""} fullWidth label="Website" name="website" value={fields.electricity.website} onChange={handleElectricChange} />
                                :
                                <ExternalLinkButton href={fields.electricity.website} >
                                    {
                                        fields.electricity.website != null && fields.electricity.website != "" ? (new URL(fields.electricity.website)).hostname : "No Website"
                                    }
                                </ExternalLinkButton>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <Typography>Auto-pay?</Typography>
                                <Checkbox disabled={!editing} checked={fields.electricity.autoPay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFields({
                                        ...fields,
                                        electricity: {
                                            ...fields.electricity,
                                            autoPay: !fields.electricity.autoPay
                                        }
                                    });
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" >
                                <IconButton onClick={() => {
                                    setNoteModalOpen(true);
                                    setNoteType(NoteTypes.electricity)
                                }}
                                >
                                    <EditNote />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Water</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Company" name="companyName" value={fields.water.companyName} onChange={handleWaterChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.water.phone} onChange={handleWaterChange} />
                        </Grid>
                        <Grid item xs={6}>
                            {editing ?
                                <TextField error={waterWebsiteError} helperText={waterWebsiteError ? INVALID_URL : ""} fullWidth label="Website" name="website" value={fields.water.website} onChange={handleWaterChange} />
                                :
                                <ExternalLinkButton href={fields.water.website} >
                                    {
                                        fields.water.website != null && fields.water.website != "" ? (new URL(fields.water.website)).hostname : "No Website"
                                    }
                                </ExternalLinkButton>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <Typography>Auto-pay?</Typography>
                                <Checkbox disabled={!editing} checked={fields.water.autoPay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFields({
                                        ...fields,
                                        water: {
                                            ...fields.water,
                                            autoPay: !fields.water.autoPay
                                        }
                                    });
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <IconButton onClick={() => {
                                    setNoteModalOpen(true);
                                    setNoteType(NoteTypes.water)
                                }}>
                                    <EditNote />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Gas</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Company" name="companyName" value={fields.gas.companyName} onChange={handleGasChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.gas.phone} onChange={handleGasChange} />
                        </Grid>
                        <Grid item xs={6}>
                            {editing ?
                                <TextField error={gasWebsiteError} helperText={gasWebsiteError ? INVALID_URL : ""} fullWidth label="Website" name="website" value={fields.gas.website} onChange={handleGasChange} />
                                :
                                <ExternalLinkButton href={fields.gas.website} >
                                    {
                                        fields.gas.website != null && fields.gas.website != "" ? (new URL(fields.gas.website)).hostname : "No Website"
                                    }
                                </ExternalLinkButton>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <Typography>Auto-pay?</Typography>
                                <Checkbox disabled={!editing} checked={fields.gas.autoPay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFields({
                                        ...fields,
                                        gas: {
                                            ...fields.gas,
                                            autoPay: !fields.gas.autoPay
                                        }
                                    });
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <IconButton onClick={() => {
                                    setNoteModalOpen(true);
                                    setNoteType(NoteTypes.gas)
                                }}>
                                    <EditNote />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Trash</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Company" name="companyName" value={fields.trash.companyName} onChange={handleTrashChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.trash.phone} onChange={handleTrashChange} />
                        </Grid>
                        <Grid item xs={6}>
                            {editing ?
                                <TextField error={trashWebsiteError} helperText={trashWebsiteError ? INVALID_URL : ""} fullWidth label="Website" name="website" value={fields.trash.website} onChange={handleTrashChange} />
                                :
                                <ExternalLinkButton fullWidth href={fields.trash.website} >
                                    {
                                        fields.trash.website != null && fields.trash.website != "" ? (new URL(fields.trash.website)).hostname : "No Website"
                                    }
                                </ExternalLinkButton>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <Typography>Auto-pay?</Typography>
                                <Checkbox disabled={!editing} checked={fields.trash.autoPay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFields({
                                        ...fields,
                                        trash: {
                                            ...fields.trash,
                                            autoPay: !fields.trash.autoPay
                                        }
                                    });
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <IconButton onClick={() => {
                                    setNoteModalOpen(true);
                                    setNoteType(NoteTypes.trash)
                                }}>
                                    <EditNote />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            <Box display="flex" flexDirection="column" sx={{
                margin: `${theme.spacing(2)}`,
                gap: theme.spacing(2),
            }}>
                <Typography variant="h5">HOA Info</Typography>
                <Card sx={{
                    padding: theme.spacing(3),
                    marginBottom: theme.spacing(2)
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">HOA Info</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Association" name="associationName" value={fields.hoaPolicy.associationName} onChange={handleHOAChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.hoaPolicy.phone} onChange={handleHOAChange} />
                        </Grid>
                        <Grid item xs={6}>
                            {editing ?
                                <TextField error={hoaWebsiteError} helperText={hoaWebsiteError ? INVALID_URL : ""} fullWidth label="Website" name="website" value={fields.hoaPolicy.website} onChange={handleHOAChange} />
                                :
                                <ExternalLinkButton href={fields.hoaPolicy.website} >
                                    {
                                        fields.hoaPolicy.website != null && fields.hoaPolicy.website != "" ? (new URL(fields.hoaPolicy.website)).hostname : "No Website"
                                    }
                                </ExternalLinkButton>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <Typography>Auto-pay?</Typography>
                                <Checkbox disabled={!editing} checked={fields.hoaPolicy.autoPay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFields({
                                        ...fields,
                                        hoaPolicy: {
                                            ...fields.hoaPolicy,
                                            autoPay: !fields.hoaPolicy.autoPay
                                        }
                                    });
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <IconButton onClick={() => {
                                    setNoteModalOpen(true);
                                    setNoteType(NoteTypes.HOA)
                                }}>
                                    <EditNote />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled={!editing} fullWidth label="Monthly Fee" name="monthlyFee" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} value={fields.hoaPolicy.monthlyFee} onChange={handleHOAChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled={!editing} fullWidth label="Annual Fee" name="annualFee" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} value={fields.hoaPolicy.annualFee} onChange={handleHOAChange} />
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Box>
    );
}