import React, { useCallback, useEffect, useState } from "react";
import { Calendar, luxonLocalizer, NavigateAction, SlotInfo, TitleOptions, View, Views, ViewsProps } from 'react-big-calendar';
import { DateTime } from "luxon";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../App.css";
import { ORIGIN } from "../../../App";
import { Box, Button, Card, Checkbox, Chip, FormControlLabel, IconButton, Menu, MenuItem, Modal, Typography, useTheme } from "@mui/material";
import { Add, ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import colors from "../../../constants/colors";
import calendarIllustration from "../../../images/calendar_illustration.svg";
import { READ_SCOPE, WRITE_SCOPE } from "../../../constants/scopes";
import { CalendarEventDTO, PropertyDTO } from "../../../constants/dataTransferObjects";
import { CALENDAR_API_PATH } from "../../../constants/apiPaths";
import { BigEventsSortByDate, capitalizeFirstLetter } from "../../../utils";
import { CalendarEventRow } from "../../calendar/CalendarEventRow";
import { BigEvent, CalendarEventPropertyInfo, CUSTOM_EVENT_PATH, CustomViewProps } from "../../calendar/calendarTypes";
import { MobileEventView } from "./MobileEventView";
import { MobileDeleteEvent } from "./MobileDeleteEvent";
import { MobileEventForm } from "./MobileEventForm";
import { MobileCalendarEventRow } from "./MobileCalendarEventRow";

export function MobileCalendarPane() {
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [propertyInfos, setPropertyInfos] = useState<CalendarEventPropertyInfo[] | null>(null);
    const [events, setEvents] = useState<BigEvent[] | null>(null);
    const [leaseEvents, setLeaseEvents] = useState<BigEvent[]>([]);
    const [hoaEvents, setHOAEvents] = useState<BigEvent[]>([]);
    const [customEvents, setCustomEvents] = useState<BigEvent[]>([]);
    const [managementFeeEvents, setManagementFeeEvents] = useState<BigEvent[]>([]);
    const [showLeaseEvents, setShowLeaseEvents] = useState<boolean>(true);
    const [showHOAEvents, setShowHOAEvents] = useState<boolean>(true);
    const [modalEvent, setModalEvent] = useState<BigEvent>();
    const [eventViewOpen, setEventViewOpen] = useState(false);
    const [createEventFormOpen, setCreateEventFormOpen] = useState(false);
    const [deleteEventOpen, setDeleteEventOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
    const [view, setView] = useState<View>("week");
    const [date, setDate] = useState<Date>(DateTime.now().toJSDate());
    const [viewMenuAnchorEl, setViewMenuAnchorEl] = useState<null | HTMLElement>(null);
    const viewMenuOpen = Boolean(viewMenuAnchorEl);

    const handleClickViewMenu = (event: React.MouseEvent<HTMLElement>) => {
        setViewMenuAnchorEl(event.currentTarget);
    };

    const handleCloseViewMenu = (selection: View) => {
        setView(selection);
        setViewMenuAnchorEl(null);
    };

    async function handleOpenEventView(event: BigEvent) {
        await setModalEvent(event);
        setEventViewOpen(true);
    };

    function handleCloseEventView() {
        setEventViewOpen(false)
        setModalEvent(undefined);
    };

    function handleCloseDeleteEvent() {
        setDeleteEventOpen(false);
        setModalEvent(undefined);
    }

    async function handleOpenCreateEventForm(slotInfo: SlotInfo) {
        console.log(slotInfo);
        setCreateEventFormOpen(true);
        setSelectedDate(DateTime.fromJSDate(slotInfo.start));
    };

    function handleCloseCreateEventForm() {
        setModalEvent(undefined);
        setCreateEventFormOpen(false);
    };

    function onClickCreateNewEvent() {
        setCreateEventFormOpen(true);
        setSelectedDate(DateTime.now());
    }

    async function UpdateEventList(showRent: boolean, showHOA: boolean) {
        let newEventList: BigEvent[] = [];
        if (showRent) newEventList = newEventList.concat(leaseEvents);
        if (showHOA) newEventList = newEventList.concat(hoaEvents);
        newEventList = newEventList.concat(managementFeeEvents);
        newEventList = newEventList.concat(customEvents);
        setEvents(newEventList);
    }

    async function onLeaseEventsCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setShowLeaseEvents(event.target.checked);
        UpdateEventList(event.target.checked, showHOAEvents);
    }

    async function onHOAEventsCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setShowHOAEvents(event.target.checked);
        UpdateEventList(showLeaseEvents, event.target.checked);
    }

    async function FetchCalendarInfo() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const leaseResponse = await fetch(ORIGIN + CALENDAR_API_PATH + "LeaseEvent", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        const hoaFeeResponse = await fetch(ORIGIN + CALENDAR_API_PATH + "HOAFeeEvent", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        const customResponse = await fetch(ORIGIN + CALENDAR_API_PATH + CUSTOM_EVENT_PATH, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        let leaseEventsBody: CalendarEventDTO[] = await leaseResponse.json();
        const newLeaseEvents = leaseEventsBody.map(e => ({
            title: e.title,
            start: DateTime.fromISO(e.start).toJSDate(),
            end: DateTime.fromISO(e.end).toJSDate(),
            allDay: e.allDay,
            resource: {
                summary: e.summary,
                id: e.id,
                calendarEventPropertyInfo: {
                    propertyAddress: e.propertyAddress,
                    propertyId: e.propertyId
                }
            }
        }));
        setLeaseEvents(newLeaseEvents);
        let hoaFeeEventsBody: CalendarEventDTO[] = await hoaFeeResponse.json();
        const newHOAFeeEvents = hoaFeeEventsBody.map(e => ({
            title: e.title,
            start: DateTime.fromISO(e.start).toJSDate(),
            end: DateTime.fromISO(e.end).toJSDate(),
            allDay: e.allDay,
            resource: {
                summary: e.summary,
                id: e.id,
                calendarEventPropertyInfo: {
                    propertyAddress: e.propertyAddress,
                    propertyId: e.propertyId
                }
            }
        }));
        setHOAEvents(newHOAFeeEvents);
        let customEventsBody: CalendarEventDTO[] = await customResponse.json();
        const newCustomEvents = customEventsBody.map(e => ({
            title: e.title,
            start: DateTime.fromISO(e.start).toJSDate(),
            end: DateTime.fromISO(e.end).toJSDate(),
            allDay: e.allDay,
            resource: {
                summary: e.summary,
                id: e.id,
                calendarEventPropertyInfo: {
                    propertyAddress: e.propertyAddress,
                    propertyId: e.propertyId
                }
            }
        }));
        setCustomEvents(newCustomEvents);
        setEvents(newHOAFeeEvents.concat(newLeaseEvents).concat(newCustomEvents));
    }

    async function FetchMinimalPropertyInfo() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const propertyListResponse = await fetch(ORIGIN + CALENDAR_API_PATH + "MinimalPropertyList", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const propertiesResponse: PropertyDTO[] = await propertyListResponse.json();
        const propertyInfosResponse: CalendarEventPropertyInfo[] = propertiesResponse.map(p => {
            return {
                propertyAddress: p.streetNumber + " " + p.streetName + ", " + p.city,
                propertyId: p.id
            };
        })
        setPropertyInfos(propertyInfosResponse);
    }

    async function submitCreate(newEvent: CalendarEventDTO) {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + CALENDAR_API_PATH + "CustomEvent", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(newEvent), // body data type must match "Content-Type" header
        });
        if (!response.ok || events == null) return; //FAILED
        const responseEvent: CalendarEventDTO = await response.json();
        let newCustomEvents: BigEvent[] = [{
            title: newEvent.title,
            start: DateTime.fromISO(newEvent.start).toJSDate(),
            end: DateTime.fromISO(newEvent.end).toJSDate(),
            allDay: newEvent.allDay,
            resource: {
                summary: responseEvent.summary,
                id: responseEvent.id,
                calendarEventPropertyInfo: {
                    propertyAddress: responseEvent.propertyAddress,
                    propertyId: responseEvent.propertyId
                }
            }
        }];
        let newEvents: BigEvent[] = [...events];
        newEvents.push(newCustomEvents[0]);
        newCustomEvents = newCustomEvents.concat(customEvents!);
        setCustomEvents(newCustomEvents);
        setEvents(newEvents);
        setCreateEventFormOpen(false);
    }

    async function submitEdit(newEvent: CalendarEventDTO) {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + CALENDAR_API_PATH + "CustomEvent/" + newEvent.id, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(newEvent), // body data type must match "Content-Type" header
        });
        if (!response.ok) return; //FAILED
        let updatedCustomEvent: BigEvent = {
            title: newEvent.title,
            start: DateTime.fromISO(newEvent.start).toJSDate(),
            end: DateTime.fromISO(newEvent.end).toJSDate(),
            allDay: newEvent.allDay,
            resource: {
                summary: newEvent.summary,
                id: newEvent.id,
                calendarEventPropertyInfo: {
                    propertyAddress: newEvent.propertyAddress,
                    propertyId: newEvent.propertyId
                }
            }
        };
        let updatedCustomEvents: BigEvent[] = customEvents.map((e) => {
            if (e.resource.id == updatedCustomEvent.resource.id) {
                return updatedCustomEvent;
            }
            else {
                return { ...e };
            }
        });
        setCustomEvents(updatedCustomEvents);
        setEvents(updatedCustomEvents.concat(hoaEvents).concat(leaseEvents).concat(managementFeeEvents));
        setCreateEventFormOpen(false);
        setEventViewOpen(false);
    }

    function promptDeleteEvent(event: BigEvent) {
        setEventViewOpen(false);
        setDeleteEventOpen(true);
        setModalEvent(event);
    }

    async function handleDeleteCustomEvent(id: number) {
        if (modalEvent == undefined) return;
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const customResponse = await fetch(ORIGIN + CALENDAR_API_PATH + CUSTOM_EVENT_PATH + id, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        if (!customResponse.ok || events == null) return;
        setCustomEvents(customEvents.filter(e => e.resource.id != id));
        setEvents(events.filter(e => e.resource.id != id));
        setDeleteEventOpen(false);
        setModalEvent(undefined);
    }

    async function handleStartEdit(event: BigEvent) {
        setModalEvent(event);
        setEventViewOpen(false);
        setCreateEventFormOpen(true);
    }

    useEffect(() => {
        if(events == null) FetchCalendarInfo();
        if(propertyInfos == null) FetchMinimalPropertyInfo();
    }, [events, propertyInfos]);

    const dateTime = DateTime.fromJSDate(date);

    const onNextClick = useCallback(() => {
        if (view === Views.DAY) setDate(DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate());
        if (view === Views.WEEK) setDate(DateTime.fromJSDate(date).plus({ week: 1 }).toJSDate());
        if (view === Views.MONTH) setDate(DateTime.fromJSDate(date).plus({ month: 1 }).toJSDate());
    }, [view, date]);

    const onPrevClick = useCallback(() => {
        if (view === Views.DAY) setDate(DateTime.fromJSDate(date).minus({ days: 1 }).toJSDate());
        if (view === Views.WEEK) setDate(DateTime.fromJSDate(date).minus({ week: 1 }).toJSDate());
        if (view === Views.MONTH) setDate(DateTime.fromJSDate(date).minus({ month: 1 }).toJSDate());
    }, [view, date]);

    function ToolbarTitle() {
        switch (view) {
            case "month":
                return dateTime.monthShort + " " + dateTime.year.toString();
            case "week":
                const startOfWeek = dateTime.startOf("week").minus({ days: 1 });
                const endOfWeek = startOfWeek.plus({ days: 6 });
                return startOfWeek.monthShort + " " + startOfWeek.day + "-" + endOfWeek.monthShort + " " + endOfWeek.day;
            case "agenda":
                return "Agenda";
        }
    }

    function WeekView({ events, date }: CustomViewProps) {
        const min = DateTime.fromJSDate(date).startOf("week").minus({ days: 1 }).toJSDate();
        const max = DateTime.fromJSDate(min).plus({ days: 6 }).toJSDate();
        const relevantEvents = events.filter((e) => e.start >= min && e.end <= max);
        relevantEvents.sort(BigEventsSortByDate);
        return (
            <Box overflow="auto" display="flex" flexDirection="column" alignContent="center" >
                {
                    relevantEvents.length != 0 ? relevantEvents.map((e) => {
                        return (
                            <Box sx={{ ":hover": { cursor: "pointer", backgroundColor: colors.violetLighter } }} onClick={() => {
                                handleOpenEventView(e);
                            }}>
                                <MobileCalendarEventRow event={e} />
                            </Box>
                        )
                    })
                        :
                        <>
                            <Box component="img" src={calendarIllustration} height="70%" margin="auto" />
                            <Typography color={colors.grey} textAlign="center">No events planned</Typography>
                        </>
                }
            </Box>
        );
    }

    WeekView.navigate = (date: Date, action: NavigateAction): Date => {
        switch (action) {
            case "NEXT":
                return DateTime.fromJSDate(date).plus({ days: 7 }).toJSDate();
            case "PREV":
                return DateTime.fromJSDate(date).minus({ days: 7 }).toJSDate();
        }
        return new Date();
    }

    WeekView.title = (date: Date, options: TitleOptions): string => "Hi";

    function AgendaView({ events, date }: CustomViewProps) {
        const min = DateTime.now().startOf("day").toJSDate();
        const relevantEvents = events.filter((e) => e.start >= min);
        console.log(relevantEvents);
        relevantEvents.sort(BigEventsSortByDate);
        return (
            <Box overflow="auto" display="flex" flexDirection="column" alignContent="center"  >
                {
                    relevantEvents.length != 0 ? relevantEvents.map((e) => {
                        return (
                            <Box sx={{ ":hover": { cursor: "pointer", backgroundColor: colors.violetLighter } }} onClick={() => {
                                handleOpenEventView(e);
                            }}>
                                <MobileCalendarEventRow event={e} />
                            </Box>
                        )
                    })
                        :
                        <>
                            <Box component="img" src={calendarIllustration} height="70%" margin="auto" />
                            <Typography color={colors.grey} textAlign="center">No events planned</Typography>
                        </>
                }
            </Box>
        );
    }

    AgendaView.navigate = (date: Date, action: NavigateAction): Date => {
        switch (action) {
            case "NEXT":
                return DateTime.fromJSDate(date).plus({ days: 7 }).toJSDate();
            case "PREV":
                return DateTime.fromJSDate(date).minus({ days: 7 }).toJSDate();
        }
        return new Date();
    }

    AgendaView.title = (date: Date, options: TitleOptions): string => "Hi";

    const views: ViewsProps<BigEvent> = {
        week: WeekView,
        month: false,
        agenda: AgendaView
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Modal onClose={handleCloseEventView} open={eventViewOpen}>
                {modalEvent !== undefined ?
                    <MobileEventView
                        event={modalEvent}
                        canDelete={customEvents?.some(e => e.resource.id === modalEvent.resource.id) ?? false}
                        handleCloseModal={handleCloseEventView}
                        handleDeleteCustomEvent={promptDeleteEvent}
                        handleStartEdit={handleStartEdit}
                    />
                    :
                    <Box />
                }
            </Modal>
            <Modal onClose={handleCloseCreateEventForm} open={createEventFormOpen}>
                <MobileEventForm
                    sourceEvent={modalEvent}
                    submitCreate={submitCreate}
                    submitEdit={submitEdit}
                    handleCloseModal={handleCloseCreateEventForm}
                    initialDate={selectedDate}
                    propertyInfos={propertyInfos}
                />
            </Modal>
            <Modal onClose={handleCloseDeleteEvent} open={deleteEventOpen} >
                {modalEvent !== undefined ?
                    <MobileDeleteEvent event={modalEvent} handleDeleteCustomEvent={handleDeleteCustomEvent} handleCloseModal={handleCloseDeleteEvent} />
                    :
                    <Box />
                }
            </Modal>
            <Menu
                anchorEl={viewMenuAnchorEl}
                open={viewMenuOpen}
                onClose={() => handleCloseViewMenu(view)}
            >
                {/* <MenuItem onClick={() => handleCloseViewMenu("month")} disableRipple>
                    {"Month"}
                </MenuItem> */}
                <MenuItem onClick={() => handleCloseViewMenu("week")} disableRipple>
                    {"Week"}
                </MenuItem>
                <MenuItem onClick={() => handleCloseViewMenu("agenda")} disableRipple>
                    {"Agenda"}
                </MenuItem>
            </Menu>
            <Card sx={{ padding: theme.spacing(1.5), margin: `${theme.spacing(2)}`, marginBottom: 0, height: "84px" }}>
                <Box display="flex" flexDirection="row" alignItems="center" height="100%">
                    <Typography variant="h6">{ToolbarTitle()}</Typography>
                    <Box flex={1} />
                    <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                        <Box margin={theme.spacing(1)}>
                            <IconButton sx={{ background: colors.violetLightest, marginLeft: theme.spacing(0.5), marginRight: theme.spacing(0.5) }} onClick={onPrevClick}>
                                <ArrowLeft color="primary" />
                            </IconButton>
                            <IconButton sx={{ background: colors.violetLightest }} onClick={onNextClick}>
                                <ArrowRight color="primary" />
                            </IconButton>
                        </Box>
                        <IconButton onClick={handleClickViewMenu}>
                            {viewMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                        </IconButton>
                        <IconButton sx={{ bgcolor: colors.violetDark }} onClick={onClickCreateNewEvent}>
                            <Add color="secondary" />
                        </IconButton>
                    </Box>
                </Box>
            </Card>
            <Card sx={{ flex: 1, margin: `${theme.spacing(2)}` }}>
                <Box sx={{ display: "flex", padding: theme.spacing(2) }}>
                    <FormControlLabel control={<Checkbox checked={showLeaseEvents} onChange={onLeaseEventsCheckedChange} />} label="Rent Events" />
                    <FormControlLabel control={<Checkbox checked={showHOAEvents} onChange={onHOAEventsCheckedChange} />} label="HOA Events" />
                    <Box sx={{ flex: 1 }} />
                </Box>
                <Calendar
                    localizer={luxonLocalizer(DateTime)}
                    // startAccessor="start"
                    // endAccessor="end"
                    style={{ flex: 1, paddingBottom: "32px" }}
                    events={events ?? []}
                    onSelectEvent={handleOpenEventView}
                    selectable
                    onSelectSlot={handleOpenCreateEventForm}
                    onSelecting={slot => false}
                    toolbar={false}
                    view={view}
                    date={date}
                    views={views}
                />
            </Card>
        </Box>
    );
}